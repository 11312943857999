<script>
import { Overlay } from 'trading-vue-js'

export default {
  name: 'LinesOverlay',
  mixins: [ Overlay ],
  computed: {
    sett() {
      return this.$props.settings
    },
  },

  methods: {
    meta_info() {
      return {
        author: 'Satoshi Smith',
        version: '1.0.0',
        contact: '<email>',
        github: '<GitHub Page>',
      }
    },

    draw(ctx) {
      const layout = this.$props.layout

      for (const line of this.sett.lines) {
        let isStart = true

        for (const point of line.points) {
          const x = layout.t2screen(point[0])
          const y = layout.$2screen(point[1])

          /**
           * Render text near the point
           */
          if (point[2]) {
            ctx.fillStyle = '#ffffff'
            ctx.font = '14px Arial'
            const metrics = ctx.measureText(point[2])
            const textWidth = metrics.width
            const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
            const textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
            const gap = textHeight

            const ty = y + actualHeight + gap * (0)
            const tx = x - textWidth / 2

            ctx.fillText(point[2], tx, ty)
          }

          if (isStart) {
            ctx.beginPath()
            ctx.moveTo(x, y)
            isStart = false
          } else {
            ctx.lineTo(x, y)
          }
        }

        ctx.lineWidth = line.width
        ctx.strokeStyle = line.color

        if (line.dashed) {
          ctx.setLineDash(line.dashed)
        } else {
          ctx.setLineDash([0, 0])
        }

        ctx.stroke()
      }
    },

    use_for() {
      return [ 'LinesOverlay' ]
    },

    data_colors() {
      return [ '#fff' ]
    },
  },

}
</script>
