<script>
import { Overlay } from 'trading-vue-js'

export default {
  name: 'RectsOverlay',
  mixins: [ Overlay ],
  data() {
    return {

    }
  },

  computed: {
    sett() {
      return this.$props.settings
    },
  },

  methods: {
    meta_info() {
      return {
        author: 'Satoshi Smith',
        version: '1.0.0',
        contact: '<email>',
        github: '<GitHub Page>',
      }
    },

    draw(ctx) {
      const layout = this.$props.layout

      for (const rect of this.sett.rects) {
        const x = layout.t2screen(rect.points[0])
        const y = layout.$2screen(rect.points[1])
        const w = layout.t2screen(rect.points[2]) - x
        const h = layout.$2screen(rect.points[3]) - y

        ctx.lineWidth = rect.borderWidth

        ctx.strokeStyle = rect.borderColor
        ctx.strokeRect(x, y, w, h)

        if (rect.bg) {
          ctx.fillStyle = rect.bg
          ctx.fillRect(x, y, w, h)
        }

        if (rect.text) {
          ctx.fillStyle = 'white'
          ctx.font = '14px Arial'
          ctx.fillText(rect.text, x - 2, y - 6)
        }
      }
    },

    use_for() {
      return [ 'RectsOverlay' ]
    },

    data_colors() {
      return [ '#fff' ]
    },
  },

}
</script>
