<script>
import { Overlay } from 'trading-vue-js'

export default {
  name: 'TextsOverlay',
  mixins: [ Overlay ],
  data() {
    return {

    }
  },

  computed: {
    sett() {
      return this.$props.settings
    },
  },

  methods: {
    meta_info() {
      return {
        author: 'Satoshi Smith',
        version: '1.0.0',
        contact: '<email>',
        github: '<GitHub Page>',
      }
    },

    draw(ctx) {
      const layout = this.$props.layout

      for (const text of this.sett.texts) {
        let x = layout.t2screen(text.x)
        let y = layout.$2screen(text.y)

        ctx.fillStyle = text.color
        ctx.font = text.font || '14px Arial'

        const metrics = ctx.measureText(text.text)
        const textWidth = metrics.width
        const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
        const textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
        const gap = textHeight

        y += actualHeight + gap * (text.lineNum || 0)

        // switch (text.origin[1]) {
        //   case 'top':
        //     y -= actualHeight + gap
        //     break

        //   case 'bottom':
        //     y += actualHeight + gap * 2
        //     break
        // }

        x -= textWidth / 2

        ctx.fillText(text.text, x, y)
      }
    },

    use_for() {
      return [ 'TextsOverlay' ]
    },

    data_colors() {
      return [ '#fff' ]
    },
  },

}
</script>
